//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Index",
  data() {
    return {
      monthOrCustom: "custom",
      timePop: false,
      timeType: "start",
      dateTxt:
        this.$moment().subtract(15, "days").format("yyyy-MM-DD") +
        " 至 " +
        this.$moment().format("yyyy-MM-DD"),
      currentDate: new Date(
        this.$moment().subtract(15, "days").format("yyyy-MM-DD")
      ),
      currentMonth: new Date(this.$moment().format("yyyy-MM")),
      tempStartDate: this.$moment().subtract(15, "days").format("yyyy-MM-DD"),
      tempEndDate: this.$moment().format("yyyy-MM-DD"),
      tempMonth: this.$moment().format("yyyy-MM"),
      maxDate: null,
      minDate: new Date(2020, 9, 1),

      noData: false,
      storeData: {},
    };
  },
  created() {
    window.document.title = "外部联系人报表";

    let startDate = this.$moment().subtract(15, "days").format("yyyy-MM-DD");
    let endDate = this.$moment().format("yyyy-MM-DD");
    this.startDate = startDate;
    this.endDate = endDate;
    this.maxDate = new Date();

    this.getData();
  },
  mounted() {
    window.document.title = "外部联系人报表";
  },
  activated() {
    window.document.title = "外部联系人报表";
  },
  methods: {
    timeChange() {
      if (this.monthOrCustom == "custom") {
        if (this.timeType == "start") {
          this.tempStartDate = this.$moment(this.currentDate).format(
            "yyyy-MM-DD"
          );
        } else if (this.timeType == "end") {
          this.tempEndDate = this.$moment(this.currentDate).format(
            "yyyy-MM-DD"
          );
        }
      } else {
        if (this.timeType == "start") {
          this.tempMonth = this.$moment(this.currentMonth).format("yyyy-MM");
        }
      }
    },
    reloadData() {
      //格式化时间格式
      if (this.monthOrCustom == "custom") {
        let start = new Date(this.tempStartDate).getTime(),
          end = new Date(this.tempEndDate).getTime();
        if (start > end) {
          //需要交换
          this.startDate = this.$moment(this.tempEndDate).format("yyyy-MM-DD");
          this.endDate = this.$moment(this.tempStartDate).format("yyyy-MM-DD");
          this.dateTxt = this.tempEndDate + " 至 " + this.tempStartDate;
        } else {
          this.startDate = this.$moment(this.tempStartDate).format(
            "yyyy-MM-DD"
          );
          this.endDate = this.$moment(this.tempEndDate).format("yyyy-MM-DD");
          this.dateTxt = this.tempStartDate + " 至 " + this.tempEndDate;
        }
      } else {
        this.startDate = this.$moment(this.tempMonth)
          .subtract(15, "days")
          .format("yyyy-MM-DD");
        // this.endDate = this.$moment(this.tempMonth).getLast()
        let month = this.tempMonth.split("-")[1];
        if (month == new Date().getMonth() + 1) {
          this.endDate = this.$moment().format("yyyy-MM-DD");
        } else {
          let end = this.$moment(this.tempMonth).endOf("month");
          this.endDate = this.$moment(end).format("yyyy-MM-DD");
        }
        this.dateTxt = this.tempMonth;
      }
      this.timePop = false;

      //加载数据
      this.getData();
    },

    getData() {
      let time1 = this.$moment(this.startDate);
      let time2 = this.$moment(this.endDate);
      console.log(time1.diff(time2, "day"));
      if (Math.abs(Number(time1.diff(time2, "day"))) > 29) {
        this.$toast({ message: "查询时间范围不能超过29天", duration: 5000 });
        return;
      }

      this.$toast.loading({
        message: "数据加载中",
        forbidClick: true,
        duration: 0,
      });
      this.storeData = {};
      const data = {
        pager: {
          pageIndex: 1,
          pageSize: 10,
          rowsCount: 0,
          start: 0,
        },
        queryMap: {
          beginDate: this.startDate,
          endDate: this.endDate,
        },
        queryMapObject: {},
      };
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/supervision/getrflist",
          data
        )
        .then((res) => {
          console.log(res);
          if (res && res.data && res.data.data) {
            this.noData = false;
            const temp = res.data.data;
            for (let key in temp) {
              this.storeData[key] = { peopleCount: temp[key] };
            }
            //数据处理
            this.getList();
          } else {
            this.noData = true;
          }
        });
    },
    getList() {
      const data = {
        pager: {
          pageIndex: 1,
          pageSize: 10,
          rowsCount: 0,
          start: 0,
        },
        queryMap: {
          beginDate: this.startDate,
          endDate: this.endDate,
        },
        queryMapObject: {},
      };
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/v1/wx/cp/supervision/getListBystore",
          data
        )
        .then((res_) => {
          if (res_ && res_.data && res_.data.data) {
            //数据处理
            const temp_ = res_.data.data;
            const tempData = {};
            temp_.forEach((item) => {
              for (let key in item) {
                tempData[key] = item[key]["behaviorList"];
              }
            });
            for (let key in tempData) {
              let list = tempData[key]||[];
              let newApplyCount = 0;
              let newContactCount = 0;
              let chatCount = 0;
              let messageCount = 0;
              let negCount = 0;
              list.forEach((item) => {
                newApplyCount += item.newApplyCnt;
                newContactCount += item.newContactCnt;
                chatCount += item.chatCnt;
                messageCount += item.messageCnt;
                negCount += item.negativeFeedbackCnt;
              });
              this.storeData[key].newApplyCount = newApplyCount;
              this.storeData[key].newContactCount = newContactCount;
              this.storeData[key].chatCount = chatCount;
              this.storeData[key].messageCount = messageCount;
              this.storeData[key].negCount = negCount;
            }
          }
          this.$toast.clear();
          this.$forceUpdate();
          console.log("门店数据", this.storeData);
        });
    },

    gotoDetail(store) {
      this.$router.push(
        "/externalStatistics/storeDetail?startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate +
          "&store=" +
          store
      );
    },
  },
};
